import React, { Component } from 'react';
import './App.css';
import Papa from 'papaparse';
import Select from 'react-select';
import CopyToClipboard from 'react-copy-to-clipboard';

class App extends Component {

  initialState = {
      version: [
        {value: '5', label: 'CTCAE v5'},
        {value: '4', label: 'CTCAE v4'},
      ],
      versionSelect: {value: '5', label: 'CTCAE v5'},
      allData: [],
      data: [],
      selected: '',
      grade: [],
      attr: [ 
        {value: '1. Unrelated: The AE is clearly NOT related to the intervention', label: '1. Unrelated: The AE is clearly NOT related to the intervention'}, 
        {value: '2. Unlikely: The AE is doubtfully related to the intervention', label: '2. Unlikely: The AE is doubtfully related to the intervention'},
        {value: '3. Possible: The AE may be related to the intervention', label: '3. Possible: The AE may be related to the intervention'},
        {value: '4. Probable: The AE is likely related to the intervention', label: '4. Probable: The AE is likely related to the intervention'},
        {value: '5. Definite: The AE is clearly related to the intervention', label: '5. Definite: The AE is clearly related to the intervention'},
        ],
      sig: [
        {value: 'Clinically significant', label: 'Clinically significant'}, 
        {value: 'Not clinically significant', label: 'Not clinically significant'}
      ],
      symptom: '',
      gradeSelect: '',
      attrSelect: '',
      sigSelect: '',
      agree: false,
      checkout: '',
      copied: false
  };

  state = this.initialState;

  dataset = [];

  resetBuilder = () => {    
    this.setState({copied: true});
  }
    
  componentDidMount () {
    Papa.parse('https://docs.google.com/spreadsheets/d/1h9MpwU0f0Gddv5ftjTsWzUJeFRvgkcxkPjm2ESZFlSc/pub?output=csv#gid=1546362431', {
      download: true,
      header: true,
      complete: this.v5Data
    });

    Papa.parse('https://docs.google.com/spreadsheets/d/1h9MpwU0f0Gddv5ftjTsWzUJeFRvgkcxkPjm2ESZFlSc/pub?output=csv#gid=1258377919', {
      download: true,
      header: true,
      complete: this.v4Data
    });
  }

 v5Data = (result) => {
    const data = result.data;
    this.setState({
      v5data: data,
      data: data 
    })
  }

  v4Data = (result) => {
    const data = result.data;
    this.setState({
      v4data: data
    })
  }

  addToList = (e) => {
    var newCheckout = this.state.checkout + this.state.selected.Code + ' | ' + this.state.selected.Symptom + ' | ' + this.state.gradeSelect.value + ' | ' + this.state.attrSelect.value + ' | ' + this.state.sigSelect.value + '\n';
    this.setState({
      checkout: newCheckout, 
      selected: '',
      symptom: '',
      grade: [], 
      gradeSelect: '', 
      attrSelect: '',
      sigSelect: '',
      copied: false
    });
  }

  clearList = (e) => {
    this.setState({
      checkout: '',
      selected: '',
      symptom: '',
      grade: [], 
      gradeSelect: '', 
      attrSelect: '',
      sigSelect: '',
      copied: false
    });
  }

  agree = () => {
    this.setState({
      agree: true
    })
  }

  changeVersion = (option) => {
    if (option.value === '4') {
      this.setState({
        data: this.state.v4data,
        versionSelect: {value: '4', label: 'CTCAE v4'}
      });
    }
    else {
      this.setState({
        data: this.state.v5data,
        versionSelect: {value: '5', label: 'CTCAE v5'}
      });
    }
  }

  render() {
    return (
      <div className="App">
        <div>
        <label>CTCAE Version</label>
          <Select
            value = {this.state.versionSelect} 
            options = {this.state.version}
            onChange = { (option) => this.changeVersion(option) }/>
          <label>Search for a Symptom</label>
          <Select 
            value = {this.state.symptom}
            options={this.state.data} 
            getOptionLabel = {(option)=>option.Symptom}
            getOptionValue = {(option)=>option.Code}
            onChange = { (option) => this.setState({ 
              gradeSelect: '',
              attrSelect: '',
              selected : option,
              symptom: option.Symptom,
              grade : [
                {value: 'Grade 1: ' + option['Grade 1'], label: 'Grade 1: ' + option['Grade 1']},
                {value: 'Grade 2: ' + option['Grade 2'], label: 'Grade 2: ' + option['Grade 2']},
                {value: 'Grade 3: ' + option['Grade 3'], label: 'Grade 3: ' + option['Grade 3']},
                {value: 'Grade 4: ' + option['Grade 4'], label: 'Grade 4: ' + option['Grade 4']},
                {value: 'Grade 5: ' + option['Grade 5'], label: 'Grade 5: ' + option['Grade 5']}
                ]
              }) } />
          <h2>{this.state.selected.Symptom}</h2>
          <p><strong>Definition:</strong> {this.state.selected.Definition}</p>
          <p><strong>Code:</strong> {this.state.selected.Code}</p>
          <label>Grade</label>
          <Select
            value = {this.state.gradeSelect} 
            options = {this.state.grade}
            onChange = { (option) => this.setState({ gradeSelect : option }) }/>
          <label>Attribution</label>
          <Select
            value = {this.state.attrSelect}
            options = {this.state.attr} 
            onChange = { (option) => this.setState({ attrSelect : option }) }/>
          <label>Clinical Significance</label>
          <Select
            value = {this.state.sigSelect}
            options = {this.state.sig} 
            onChange = { (option) => this.setState({ sigSelect : option }) }/>
        </div>

        <div className="disclaimer" style={this.state.agree ? {display: 'none'} : {}}>
          <p>The symptoms, grades and attributions listed here were taken from the <a href="https://ctep.cancer.gov/protocoldevelopment/electronic_applications/ctc.htm">National Cancer Institute (NCI), Division of Cancer Treatment and Diagnosis website</a>.  This website ctcae-online.org or its developer boxcarstudio.com does not take any responsibility for errors which may have occurred during the transfer of content from NCI to this website. Users should take responsibility of checking the accuracy of this website prior to using the contents in critical situations, such as documentation in patient chart or medical records. The information provided on this website is "as-is" and ctcae-online.org makes no  no representations or warranties in relation to the medical information on this website.</p>

          <p>I agree that ctcae-online.org will not be held responsible for any errors or consequences in management of patient care resulting from such errors. I take responsibility of making sure that the grading of symptoms is correct by verifying it by using the original <a href="https://ctep.cancer.gov/protocoldevelopment/electronic_applications/ctc.htm">NCI document available here</a>.</p>
          <label><input type="checkbox" name="agree" onChange= {() => this.agree()} /><span>I Agree</span></label>
        </div>

        <div className="checkout" style={this.state.agree ? {} : { display: 'none'}}>
          <button onClick={this.addToList}>Add To List</button>
          <pre>{this.state.checkout}</pre>
          <CopyToClipboard text={this.state.checkout}
            onCopy={() => this.resetBuilder()}>
            <button>Copy to Clipboard</button>
          </CopyToClipboard>
          {this.state.copied ? <span style={{color: 'red', textAlign: 'center', display: 'block'}}>Copied.</span> : null}
          <button onClick={this.clearList}>Clear List</button>
        </div>
        <div className="footer">
          <p><strong><a href="/ctcae-instructions.pdf" target="_blank">How to use this website</a></strong></p>
          <p>This website was made possible by the generous grant by the Michigan Ovarian Cancer Alliance (MIOCA) to Dr. Shitanshu Uppal.</p>
          <p>The idea and design for this website was developed by Dr. Shitanshu Uppal and Liam Dalton. The website was developed by <a href="https://boxcarstudio.com">Boxcar Studio</a></p>
        </div>
      </div>
    );
  }
}

export default App;
